<template>
  <div class="artist-header-overview" v-loading="loading">
    <div class="d-flex flex-wrap flex-stack">
      <div class="d-flex flex-column flex-grow-1 pe-8">
        <div class="d-flex flex-wrap">
          <div
            class="
              border border-gray-300 border-dashed
              rounded
              min-w-125px
              py-3
              px-4
              me-6
              mb-3
            "
          >
            <div class="d-flex align-items-center justify-content-center">
              <span
                :class="[
                  'svg-icon',
                  'svg-icon-3',
                  'me-2',
                  followersIncreased ? 'svg-icon-success' : 'svg-icon-danger',
                ]"
              >
                <inline-svg
                  :src="
                    followersIncreased
                      ? '/media/icons/duotune/arrows/arr066.svg'
                      : '/media/icons/duotune/arrows/arr065.svg'
                  "
                />
              </span>
              <div
                class="fs-3 fw-bolder"
                data-kt-countup="true"
                data-kt-countup-value="4500"
                data-kt-countup-prefix="$"
              >
                {{ currentSpotifyMonthlyListeners.toLocaleString() }}
              </div>
            </div>
            <div class="fw-bold fs-7 text-gray-400">Monthly Listeners</div>
          </div>
          <div
            class="
              border border-gray-300 border-dashed
              rounded
              min-w-125px
              py-3
              px-4
              me-6
              mb-3
            "
          >
            <div class="d-flex align-items-center justify-content-center">
              <span
                :class="[
                  'svg-icon',
                  'svg-icon-3',
                  followersIncreased ? 'svg-icon-success' : 'svg-icon-danger',
                  'me-2',
                ]"
              >
                <inline-svg
                  :src="
                    followersIncreased
                      ? '/media/icons/duotune/arrows/arr066.svg'
                      : '/media/icons/duotune/arrows/arr065.svg'
                  "
                />
              </span>
              <div
                class="fs-3 fw-bolder"
                data-kt-countup="true"
                data-kt-countup-value="75"
              >
                {{ spotifyGrowth + "%" }}
              </div>
            </div>
            <div class="fw-bold fs-7 text-gray-400">Yearly Growth</div>
          </div>
          <div
            class="
              border border-gray-300 border-dashed
              rounded
              min-w-125px
              py-3
              px-4
              me-6
              mb-3
            "
          >
            <div class="d-flex align-items-center justify-content-center">
              <div
                class="fs-3 fw-bolder"
                data-kt-countup="true"
                data-kt-countup-value="4500"
                data-kt-countup-prefix="$"
              >
                {{ `€ ${lastYearRevenue.toLocaleString()}` }}
              </div>
            </div>
            <div class="fw-bold fs-7 text-gray-400">Revenues Last Year</div>
          </div>
          <div
            class="
              border border-gray-300 border-dashed
              rounded
              min-w-125px
              py-3
              px-4
              me-6
              mb-3
            "
          >
            <div class="d-flex align-items-center justify-content-center">
              <div
                class="fs-3 fw-bolder"
                data-kt-countup="true"
                data-kt-countup-value="4500"
                data-kt-countup-prefix="$"
              >
                {{ totalFanbase.toLocaleString() }}
              </div>
            </div>
            <div class="fw-bold fs-7 text-gray-400">Total Fanbase</div>
          </div>
          <div
            v-if="totalPlaylists"
            class="
              border border-gray-300 border-dashed
              rounded
              min-w-125px
              py-3
              px-4
              me-6
              mb-3
            "
          >
            <div class="d-flex align-items-center justify-content-center">
              <div
                class="fs-3 fw-bolder"
                data-kt-countup="true"
                data-kt-countup-value="4500"
                data-kt-countup-prefix="$"
              >
                {{ totalPlaylists.toLocaleString() }}
              </div>
            </div>
            <div class="fw-bold fs-7 text-gray-400">Total Playlists</div>
          </div>
        </div>
      </div>
      <div
        class="d-flex align-items-center w-200px w-sm-300px flex-column mt-3"
      ></div>
    </div>
  </div>
</template>
<script>
import { onMounted, ref, watch } from "vue";
import useSpotifyMonthlyListeners from "../../common/composables/useSpotifyMonthlyListeners";
import { getTotalPlaylistOnSpotify } from "@/api/artists.api";

export default {
  name: "ArtistHeaderOverView",
  props: {
    uuid: {
      type: String,
      default: null,
    },
    totalFanbase: {
      type: Number,
      required: true,
    },
    totalPlaylists: {
      type: Number,
      default: 0,
    },
  },
  setup(props, context) {
    const loading = ref(true);
    const followersIncreased = ref(true);
    const currentSpotifyMonthlyListeners = ref(0);
    const spotifyGrowth = ref(0);
    const totalSpotifyListeners = ref([]);
    const yearBackSpotifyMonthlyListeners = ref(0);

    const lastYearRevenue = ref("");

    const calculateLast12MonthsRevenue = () => {
      const data = totalSpotifyListeners.value.map((totalYearlyFollowers) => {
        const totalSpotifyPlays = totalYearlyFollowers * 2.1;
        const revenueSpotify = totalSpotifyPlays * 0.003;
        const revenuesAllStreamingPlatforms = (revenueSpotify / 40) * 100;
        const totalRevenue = (revenuesAllStreamingPlatforms / 85) * 100;
        return Math.round(totalRevenue);
      });
      lastYearRevenue.value = data.reduce(
        (previousValue, currentValue) => previousValue + currentValue,
        0
      );
      // store.dispatch("ArtistModule/setLastYearRevenues", totalRevenue);
    };

    const calculatePercentageChange = (currentValue, previousValue) => {
      if (currentValue > previousValue) {
        spotifyGrowth.value = Math.floor(
          ((currentValue - previousValue) / previousValue) * 100
        );
      } else {
        followersIncreased.value = false;
        spotifyGrowth.value = Math.floor(
          ((previousValue - currentValue) / previousValue) * 100
        );
      }
      context.emit("updateGrowthRate", {
        growthRate: spotifyGrowth.value,
        followersIncreased: followersIncreased.value,
      });
    };

    const fetchData = async () => {
      followersIncreased.value = true;
      currentSpotifyMonthlyListeners.value = 0;
      spotifyGrowth.value = 0;
      totalSpotifyListeners.value = [];
      yearBackSpotifyMonthlyListeners.value = 0;
      try {
        const { spotifyData } = await useSpotifyMonthlyListeners(props.uuid);
        if (
          spotifyData.value.monthlyListeners &&
          spotifyData.value.monthlyListeners.length
        ) {
          currentSpotifyMonthlyListeners.value =
            spotifyData.value.monthlyListeners[
              spotifyData.value.monthlyListeners.length - 1
            ];
          yearBackSpotifyMonthlyListeners.value =
            spotifyData.value.monthlyListeners[0];
          totalSpotifyListeners.value = [...spotifyData.value.monthlyListeners];
          calculateLast12MonthsRevenue();
          calculatePercentageChange(
            currentSpotifyMonthlyListeners.value,
            yearBackSpotifyMonthlyListeners.value
          );
        }
      } catch (e) {
        console.error(e);
      } finally {
        loading.value = false;
      }
    };

    watch(
      () => props.uuid,
      (value) => {
        if (value) {
          fetchData();
        }
      },
      { immediate: true }
    );

    return {
      loading,
      followersIncreased,
      currentSpotifyMonthlyListeners,
      spotifyGrowth,
      lastYearRevenue,
    };
  },
};
</script>

<style scoped></style>
