<template>
  <div class="artist-detail">
    <BandInfo @updateGrowthRate="updateGrowthRate" />
    <router-view />
  </div>
</template>

<script>
import BandInfo from "../components/BandInfo";
import { computed, provide, ref } from "vue";
export default {
  components: {
    BandInfo,
  },
  name: "ArtistDetail",
  setup() {
    const growthRate = ref({ growthRate: 0, followersIncreased: true });

    provide(
      "growthRate",
      computed(() => growthRate.value)
    );

    const updateGrowthRate = (rate) => {
      growthRate.value = rate;
    };
    return {
      growthRate,
      updateGrowthRate,
    };
  },
};
</script>
<style lang="scss" scoped></style>
